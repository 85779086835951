if($('#gmap_block').length) {
    window.GMAP = {
        data: {
            options_gmap_cluster: {}
        },

        init: async () => {
            if(typeof google != 'undefined') {
                var map_style = [
                    {
                        "featureType": "landscape.man_made",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "landscape.natural",
                        "elementType": "geometry",
                        "stylers": [
                            {
                                "color": "#dde2e3"
                            },
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "all",
                        "stylers": [
                            {
                                "color": "#c6e8b3"
                            },
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#c6e8b3"
                            },
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels",
                        "stylers": [
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "road",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#c1d1d6"
                            },
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.stroke",
                        "stylers": [
                            {
                                "color": "#a9b8bd"
                            },
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "all",
                        "stylers": [
                            {
                                "color": "#f8fbfc"
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "labels.text",
                        "stylers": [
                            {
                                "color": "#979a9c"
                            },
                            {
                                "visibility": "on"
                            },
                            {
                                "weight": 0.5
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "labels.text.fill",
                        "stylers": [
                            {
                                "visibility": "on"
                            },
                            {
                                "color": "#827e7e"
                            }
                        ]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "labels.text.stroke",
                        "stylers": [
                            {
                                "color": "#3b3c3c"
                            },
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.line",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "on"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.station.airport",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.station.bus",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "transit.station.rail",
                        "elementType": "all",
                        "stylers": [
                            {
                                "visibility": "off"
                            }
                        ]
                    },
                    {
                        "featureType": "water",
                        "elementType": "geometry.fill",
                        "stylers": [
                            {
                                "color": "#a6cbe3"
                            },
                            {
                                "visibility": "on"
                            }
                        ]
                    }
                ];


                /* dark */
                if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                    map_style = [
                        {
                            "stylers": [
                                {
                                    "hue": "#ff1a00"
                                },
                                {
                                    "invert_lightness": true
                                },
                                {
                                    "saturation": -100
                                },
                                {
                                    "lightness": 33
                                },
                                {
                                    "gamma": 0.5
                                }
                            ]
                        },
                        {
                            "featureType": "water",
                            "elementType": "geometry",
                            "stylers": [
                                {
                                    "color": "#2D333C"
                                }
                            ]
                        }
                    ];
                }

                window.gmap = new google.maps.Map(document.getElementById('gmap_block'), {
                    zoom: 10,
                    maxZoom: 8,
                    center: window.map_center,
                    gestureHandling: 'greedy',
                    disableDefaultUI: true,
                    scrollwheel: false,
                    styles: map_style
                });
                window.gmap.setOptions({minZoom: 4, maxZoom: 30});


                ///////////////////////////////////////////////////

                var getGoogleClusterInlineSvg = function(color) {
                    var encoded = window.btoa('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" enable-background="new 0 0 200 200"><path d="m99.5 187.5c-48.5 0-88-39.5-88-88s39.5-88 88-88 88 39.5 88 88-39.5 88-88 88" fill="#fff"/><path d="M99.5,20c43.8,0,79.5,35.7,79.5,79.5S143.3,179,99.5,179S20,143.3,20,99.5S55.7,20,99.5,20 M99.5,3C46.2,3,3,46.2,3,99.5S46.2,196,99.5,196S196,152.8,196,99.5S152.8,3,99.5,3L99.5,3z" fill="'+color+'"/></svg>');
                    return ('data:image/svg+xml;base64,' + encoded);
                };


                window.GMAP.data.options_gmap_cluster = {
                    /*gridSize: 200,*/
                    styles: [
                        {
                            width: 40,
                            height: 40,
                            url: getGoogleClusterInlineSvg(window.main_color),
                            textColor: window.main_color,
                            textSize: 12
                        },
                        {
                            width: 50,
                            height: 50,
                            url:  getGoogleClusterInlineSvg(window.main_color),
                            textColor: window.main_color,
                            textSize: 14
                        },
                        {
                            width: 60,
                            height: 60,
                            url:  getGoogleClusterInlineSvg(window.main_color),
                            textColor: window.main_color,
                            textSize: 16
                        },
                        {
                            width: 70,
                            height: 70,
                            url:  getGoogleClusterInlineSvg(window.main_color),
                            textColor: window.main_color,
                            textSize: 18
                        },
                        {
                            width: 80,
                            height: 80,
                            url:  getGoogleClusterInlineSvg(window.main_color),
                            textColor: window.main_color,
                            textSize: 20
                        }
                    ]
                };

                window.GMAP.parseGmapMarkers(window.chargers);
            }
        },

        parseGmapMarkers: async (chargers) => {

            if(typeof window.markers != 'undefined') {
                $.each(window.markers, function (index, marker) {
                    window.markers[index].setMap(null);
                });

                if(typeof window.markerCluster != 'undefined') {
                    window.markerCluster.clearMarkers();
                }
            }

            window.markers = [];


            $.each(chargers, function (index, charger) {
                if(typeof google != 'undefined') {
                    switch (charger.status) {
                        case "offline":
                            var icon = {
                                url: window.gmap_img.charger_offline,
                                scaledSize: new google.maps.Size(50, 50),
                                origin: new google.maps.Point(0, 0)
                            };
                            break;
                        case "repair":
                            var icon = {
                                url: window.gmap_img.charger_repair,
                                scaledSize: new google.maps.Size(50, 50),
                                origin: new google.maps.Point(0, 0)
                            };
                            break;
                        default:
                            var icon = {
                                url: window.gmap_img.charger,
                                scaledSize: new google.maps.Size(50, 50),
                                origin: new google.maps.Point(0, 0)
                            };
                            break;
                    }

                    var marker = new google.maps.Marker({
                        position: {
                            'lat': parseFloat(charger.location.lat),
                            'lng': parseFloat(charger.location.lng)
                        },
                        map: window.gmap,
                        icon: icon,
                        pk: charger.pk,
                        connectors_count: charger.connectors_count,
                        gps: {
                            lat: parseFloat(charger.location.lat),
                            lng: parseFloat(charger.location.lng)
                        },
                        draggable: false,
                        href: charger.href
                    });

                    marker.addListener('click', function () {
                        window.location.href = marker.href;
                    });

                    window.markers.push(marker);
                }
            });

            if (window.markers.length > 0) {
                window.markerCluster = new MarkerClusterer(window.gmap, window.markers, window.GMAP.data.options_gmap_cluster);
            }
        }
    };
}